@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
  margin: 0;
  background: #000000;
}

#hud-title {
  color: #00ff23;
  position: absolute;
  left: 2%;
  top: 3%;
  font-size: 1.25rem;
  font-family: monospace;
  text-shadow: 1px 1px 2px black;
}

#hud-instructions {
  color: rgb(0, 255, 35);
  position: absolute;
  left: 2%;
  bottom: 3%;
  font-size: 1.25rem;
  font-family: monospace;
  text-shadow: 1px 1px 2px black;
}

#hud-progress {
  color: rgb(0, 255, 35);
  position: absolute;
  right: 2%;
  top: 3%;
  font-size: 1.25rem;
  font-family: monospace;
  text-shadow: 1px 1px 2px black;
}

#hud-ae {
  color: rgb(0, 255, 35);
  position: absolute;
  right: 2%;
  bottom: 3%;
  font-size: 1.25rem;
  font-family: monospace;
  text-shadow: 1px 1px 2px black;
}

#hud-ae a {
  color: rgb(0, 255, 35);
  text-decoration: none;
  font-size: 1.25rem;
  font-family: monospace;
  text-shadow: 1px 1px 2px black;
}

#hud-ae a:hover {
  text-decoration: underline;
}

#hud-border {
  color: rgb(0, 255, 35);
  position: absolute;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-left: 1%;
  margin-right: 1%;
  border: 1px solid;
  border-radius: 20px;
  width: 98%;
  height: 96%;
  left: 0;
  top: 0;
}

#hud-health {
  position: absolute;
  right: 2%;
  top: 7%;
  font-size: 1.25rem;
  font-family: monospace;
  text-shadow: 1px 1px 2px black;
}

#hud-health-label {
  color: rgb(0, 255, 35);
}

#hud-health-label-backup {
  color: rgb(255, 0, 0);
  animation: blink 1s linear infinite;
}

#hud-health-bar {
  background: rgb(0, 255, 35);
}

#hud-health-bar-backup {
  background: rgb(255, 0, 0);
  font-size: 2rem;
}

#game-over {
  color: rgb(255, 0, 0);
  font-family: monospace;
  font-size: 1.25rem;
  margin: auto;
  text-align: center;
  padding-top: 20%;
}

.interactionModal {
  width: 700px;
  height: 400px;
  padding: 2rem;
  background: black;
  opacity: 0.9;
  font-family: monospace;
  font-size: 1.25rem;
  text-wrap: wrap;
  color: white;
  border-radius: 20px;
}

.intro {
  width: 100%;
  heigh: 50%;
  color: rgb(0, 255, 35);
  font-size: 1.25rem;
  font-family: monospace;
}

.spacer {
  height: 20px;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}
